import { differenceInCalendarDays, parseISO } from 'date-fns'
/**
 * Methods for comparing dates
 */

/**
 * Get the number of days between the supplied date and today
 */
export function compareDateTime(datetime: string) {
  return differenceInCalendarDays(parseISO(datetime), new Date())
}
