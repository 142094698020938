import { useEffect, useReducer, useState } from 'react'
import { requestJson, requestRaw } from './request'
import { dataFetchReducer } from './reducers'

import { FETCH_FAILURE, FETCH_INIT, FETCH_SUCCESS, FETCH_RESET, FetchState, PayLoadMapper } from './types'

/**
 * A hook for posting data to the api
 * @param url
 * @param mapper
 * @constructor
 */
const ApiAbstractHook = <T extends unknown>(
  url: string,
  mapper: PayLoadMapper<T>,
  method: 'POST' | 'PATCH' | 'DELETE' | 'PUT'
): [FetchState<T>, (any) => void, () => void] => {
  const [body, setBody] = useState({})
  const [act, setAct] = useState(false)

  const doAction = (body) => {
    setBody(body)
    setAct(true)
  }

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    isLoaded: false,
    data: undefined,
    error: undefined
  })

  const fetchData = async () => {
    dispatch({ type: FETCH_INIT })

    try {
      if (method === 'DELETE') {
        await requestRaw({ path: url, method: method })
        dispatch({ type: FETCH_SUCCESS, data: {} })
      } else {
        const result = await requestJson({ path: url, method: method, body })
        const data = mapper(result)
        dispatch({ type: FETCH_SUCCESS, data })
      }
    } catch (error) {
      dispatch({ type: FETCH_FAILURE, error })
    }
  }

  const reset = () => {
    dispatch({ type: FETCH_RESET })
  }

  useEffect(() => {
    if (act) {
      setAct(false)
      fetchData()
    }
  }, [act])

  return [state, doAction, reset] as [FetchState<T>, (any) => void, () => void]
}

export default ApiAbstractHook
