import { HttpError } from './errors'

export const FETCH_INIT = 'FETCH_INIT'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_FAILURE = 'FETCH_FAILURE'
export const FETCH_RESET = 'FETCH_RESET'

/**
 * The state which will be returned by the hook
 */
export interface FetchState<T> {
  isLoading: boolean
  isError: boolean
  isLoaded: boolean
  data?: T
  error?: HttpError
}

/**
 * The state which will be returned by a GetMany hook
 */
export interface FetchManyState<T> {
  isLoading: boolean
  isError: boolean
  isLoaded: boolean
  hasMore: boolean
  totalCount: number
  isLoadingMore: boolean
  data?: T[]
  error?: HttpError
}

/**
 * Type for a mapper which must be passed into the hook
 */
export type PayLoadMapper<T> = (payload: any) => T

/*
 * Action used internally by reducer
 */
export type FetchAction<T> =
  | { type: typeof FETCH_INIT }
  | { type: typeof FETCH_RESET }
  | { type: typeof FETCH_SUCCESS; data: T }
  | { type: typeof FETCH_FAILURE; error: any }

/*
 * Action used internally by reducer
 */
export type FetchManyAction<T> =
  | { type: typeof FETCH_RESET }
  | { type: typeof FETCH_INIT }
  | { type: typeof FETCH_SUCCESS; data: T[]; page: number; totalPages: number; totalCount: number }
  | { type: typeof FETCH_FAILURE; error: any }
