import { ApiRequest } from './request'

export class HttpError extends Error {
  public httpStatus: number
  public errorCode: string
  public message: string
  public details: any
  public request: ApiRequest

  constructor(httpStatus: number, errorCode: string, message: string, details: any, request: ApiRequest) {
    super(message)
    this.name = 'HttpError'
    this.errorCode = errorCode
    this.httpStatus = httpStatus
    this.details = details
    this.request = request
  }
}
