/*
 * Reducer for managing the state of a fetch request. Used by api hooks
 */
import {
  FETCH_FAILURE,
  FETCH_INIT,
  FETCH_SUCCESS,
  FETCH_RESET,
  FetchAction,
  FetchState,
  FetchManyAction,
  FetchManyState
} from './types'

export function dataFetchReducer<T>(state: FetchState<T>, action: FetchAction<T>): FetchState<T> {
  switch (action.type) {
    case FETCH_RESET:
      return {
        isLoading: false,
        isError: false,
        isLoaded: false
      }
    case FETCH_INIT:
      return {
        isLoading: true,
        isError: false,
        isLoaded: false
      }
    case FETCH_SUCCESS:
      return {
        isLoading: false,
        isError: false,
        isLoaded: true,
        data: action.data
      }
    case FETCH_FAILURE:
      return {
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: action.error
      }
    default:
      throw new Error()
  }
}

export function dataFetchManyReducer<T>(state: FetchManyState<T>, action: FetchManyAction<T>): FetchManyState<T> {
  switch (action.type) {
    case FETCH_RESET: {
      return {
        ...state,
        isError: false,
        isLoading: false,
        isLoaded: false,
        isLoadingMore: false,
        hasMore: false,
        data: []
      }
    }
    case FETCH_INIT: {
      return {
        ...state,
        isError: false,
        isLoading: !state.isLoaded,
        isLoadingMore: state.isLoaded
      }
    }
    case FETCH_SUCCESS: {
      return {
        isLoading: false,
        isError: false,
        isLoaded: true,
        isLoadingMore: false,
        hasMore: action.totalPages > action.page,
        totalCount: action.totalCount,
        data: state.isLoaded ? state.data.concat(action.data) : action.data
      }
    }
    case FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isError: true,
        isLoadingMore: false,
        hasMore: false,
        error: action.error
      }
    }
    default: {
      throw new Error()
    }
  }
}
