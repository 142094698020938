import { FetchState, PayLoadMapper } from './types'
import useApiAbstractHook from './useApiAbstractHook'

/**
 * A hook for posting data to the api
 * @param url
 * @param mapper
 * @constructor
 */

const ApiPostHook = <T extends unknown>(
  url: string,
  mapper: PayLoadMapper<T>
): [FetchState<T>, (any) => void, () => void] => {
  return useApiAbstractHook(url, mapper, 'POST')
}

export default ApiPostHook
