export const downloadCSV = (data) => {
  const stagingUrls = ['pr-', 'app-staging', 'localhost']
  try {
    const baseUrl = stagingUrls.some((string) => window.location.hostname.includes(string))
      ? 'https://api-staging.ptrl.io'
      : 'https://api.ptrl.io'

    const downloadUrl = `${baseUrl}${data}`

    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = 'sample.csv'

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
  } catch (error) {
    console.error('Error downloading CSV:', error)
  }
}
