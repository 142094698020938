import { FETCH_FAILURE, FETCH_INIT, FETCH_SUCCESS, FETCH_RESET, FetchManyState, PayLoadMapper } from './types'

import { useEffect, useReducer, useState } from 'react'
import { dataFetchManyReducer } from './reducers'
import { requestJson } from './request'

/**
 * A hook for getting a list of data from the API
 * Which includes methods for loading further pages
 * @param url
 * @param mapper
 * @constructor
 */
const ApiGetManyHook = <T extends unknown>(
  url: string,
  pageSize: number,
  mapper: PayLoadMapper<T>
): [FetchManyState<T>, (any) => void, () => void] => {
  function listMapper(data) {
    return data.data.map((item) => mapper(item))
  }

  const [query, setQuery] = useState({})
  const [page, setPage] = useState<number>(0)

  const getFirstPage = (query) => {
    dispatch({ type: FETCH_RESET })
    setQuery(query)
    setPage(1)
  }

  const getNextPage = () => {
    setPage((prevState) => prevState + 1)
  }

  const [state, dispatch] = useReducer(dataFetchManyReducer, {
    isLoading: false,
    isError: false,
    isLoaded: false,
    isLoadingMore: false,
    hasMore: false,
    data: undefined,
    totalCount: 0,
    error: undefined
  })

  const fetchData = async (page: number) => {
    dispatch({ type: FETCH_INIT })

    const queryString = { ...query, limit: pageSize, page }

    try {
      const result = await requestJson({ path: url, method: 'GET', query: queryString })
      const data = listMapper(result)

      dispatch({
        type: FETCH_SUCCESS,
        data,
        totalPages: result.meta.pagination.total_pages,
        totalCount: result.meta.pagination.total,
        page: result.meta.pagination.current_page
      })
    } catch (error) {
      dispatch({ type: FETCH_FAILURE, error })
    }
  }

  useEffect(() => {
    if (page > 0) {
      fetchData(page)
    }
  }, [page, query])

  return [state, getFirstPage, getNextPage] as [FetchManyState<T>, (any) => void, () => void]
}

export default ApiGetManyHook
