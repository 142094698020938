import { FetchState, PayLoadMapper } from './types'

import useApiAbstractHook from './useApiAbstractHook'

/**
 * A hook for putting data to the api
 * @param url
 * @param mapper
 * @constructor
 */

const ApiPutHook = <T extends unknown>(
  url: string,
  mapper: PayLoadMapper<T>
): [FetchState<T>, (any) => void, () => void] => {
  return useApiAbstractHook(url, mapper, 'PUT')
}

export default ApiPutHook
