import { addMinutes, addDays, addMonths } from 'date-fns'
import { format } from 'date-fns-tz'

/**
 * Methods for modifying dates
 */

/**
 * A utc timestamp which can be sent to the API e.g. for an expiry token
 */
export function getTimestamp(minutes: number) {
  return format(addMinutes(new Date(), minutes), 'yyyy-MM-dd HH:mm:ss', { timeZone: 'utc' })
}

/**
 * A utc date which can be sent to the API e.g. for an activities query
 */
export function getDate(offset: number, units: 'days' | 'months') {
  if (units == 'days') {
    return format(addDays(new Date(), offset), 'yyyy-MM-dd', { timeZone: 'utc' })
  } else {
    return format(addMonths(new Date(), offset), 'yyyy-MM-dd', { timeZone: 'utc' })
  }
}
