/** Basic wrapper for custom events attached to window.
 * Used for our own front end events (prefix the event name with papertrail:) and for Pusher events.
 * Pusher events are picked up by the session utility MFE and broadcast as window events so any MFE can read them
 *
 * If you subscribe to a listener, store the returned ref so you can unsubscribe when the component is unmounted
 */

type EventListenerRef = {
  eventNames: string[]
  listener: (evt: CustomEvent) => void
}

const EventManager = {
  unsubscribe: function (listenerRef: EventListenerRef) {
    listenerRef.eventNames.forEach((eventName: string) => {
      window.removeEventListener(eventName, listenerRef.listener)
    })
  },
  subscribe: function (eventNames: string[], callback) {
    const listener = (evt: CustomEvent) => {
      callback(evt.detail)
    }
    eventNames.forEach((eventName: string) => {
      window.addEventListener(eventName, listener)
    })

    return { eventNames, listener }
  },
  broadcast: function (eventName: string, data: any) {
    window.dispatchEvent(new CustomEvent(eventName, { detail: data }))
  }
}

export default EventManager
