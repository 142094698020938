import { useEffect, useReducer, useState } from 'react'
import { requestJson } from './request'
import { dataFetchReducer } from './reducers'
import { FETCH_FAILURE, FETCH_INIT, FETCH_SUCCESS, FetchState, PayLoadMapper } from './types'

/**
 * A hook for getting data from the API
 * @param url
 * @param mapper
 * @constructor
 */
const ApiGetHook = <T extends unknown>(url: string, mapper: PayLoadMapper<T>): [FetchState<T>, (any) => void] => {
  const [query, setQuery] = useState({})
  const [get, setGet] = useState(false)

  const doGet = (query) => {
    setQuery(query)
    setGet(true)
  }

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    isLoaded: false,
    data: undefined,
    error: undefined
  })

  const fetchData = async () => {
    dispatch({ type: FETCH_INIT })

    try {
      const result = await requestJson({ path: url, method: 'GET', query })
      const data = mapper(result)
      dispatch({ type: FETCH_SUCCESS, data })
    } catch (error) {
      dispatch({ type: FETCH_FAILURE, error })
    }
  }

  useEffect(() => {
    if (get) {
      setGet(false)
      fetchData()
    }
  }, [get])

  return [state, doGet] as [FetchState<T>, (any) => void]
}

export default ApiGetHook
