import { FetchState, PayLoadMapper } from './types'
import useApiAbstractHook from './useApiAbstractHook'

/**
 * A hook for calling an API delete endpoint
 * @param url
 * @param mapper
 * @constructor
 */

const ApiDeleteHook = <T extends unknown>(
  url: string,
  mapper: PayLoadMapper<T>
): [FetchState<T>, (any) => void, () => void] => {
  const [state, doAction, reset] = useApiAbstractHook(url, mapper, 'DELETE')
  const doDelete = () => {
    doAction({})
  }
  return [state, doDelete, reset] as [FetchState<T>, (any) => void, () => void]
}

export default ApiDeleteHook
