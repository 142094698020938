import { parseISO, formatDistanceStrict, differenceInDays, isToday, isTomorrow, isYesterday } from 'date-fns'
import { utcToZonedTime, format } from 'date-fns-tz'
import type { DateTimeConfig } from './types'

/** The original formats are for momentJS and are stored in user profiles so tricky to change.
 * This maps existing format strings to equivalent formats for date-fns */
const formatMap = {
  'Do MMM YYYY': 'do MMM yyyy',
  'DD/MM/YY': 'dd/MM/yy',
  'DD.MM.YY': 'dd.MM.yy',
  'DD.MM.YYYY': 'dd.MM.yyyy',
  'MM/DD/YYYY': 'MM/dd/yyyy',
  'DD/MM/YYYY': 'dd/MM/yyyy',
  'YYYY-MM-DD': 'yyyy-MM-dd',
  'H:mm a': "h:mm aaaaa'm'",
  'HH:mm': 'HH:mm'
}

export function formatDateTime(datetime: string, config: DateTimeConfig, includeTime: boolean) {
  const dateFormat = formatMap[config.dateFormat]
  const timeFormat = formatMap[config.timeFormat]

  if (config.relative) {
    return formatDuration(datetime, config, includeTime)
  } else if (includeTime) {
    const parsedTime = parseISO(datetime)
    const zonedTime = utcToZonedTime(parsedTime, config.timeZone)
    return format(zonedTime, `${dateFormat} ${timeFormat}`)
  } else {
    const parsedTime = parseISO(datetime)
    const zonedTime = utcToZonedTime(parsedTime, config.timeZone)
    return format(zonedTime, dateFormat)
  }
}

/**
 * Formatting duration requires the timezone so that we can show today/tomorrow/yesterday correctly in different timezones
 */
export function formatDuration(datetime: string, config: DateTimeConfig, includeTime: boolean) {
  const now = utcToZonedTime(new Date(), config.timeZone)
  const dt = utcToZonedTime(parseISO(datetime), config.timeZone)

  if (isToday(dt)) {
    return 'Today' + (includeTime ? ` at ${format(dt, formatMap[config.timeFormat])}` : '')
  } else if (isYesterday(dt)) {
    return 'Yesterday' + (includeTime ? ` at ${format(dt, formatMap[config.timeFormat])}` : '')
  } else if (isTomorrow(dt)) {
    return 'Tomorrow' + (includeTime ? ` at ${format(dt, formatMap[config.timeFormat])}` : '')
  } else if (differenceInDays(dt, now) <= 45) {
    return formatDistanceStrict(dt, now, { addSuffix: true, unit: 'day' })
  } else {
    return formatDistanceStrict(dt, now, { addSuffix: true })
  }
}
